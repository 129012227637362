import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { register } from 'swiper/element/bundle';

@Component({
	standalone: true,
	selector: 'cms-root',
	templateUrl: './app.component.html',
	imports: [RouterModule],
})
export class AppComponent {
	constructor() {
		register();
	}
}
