import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent, SidebarComponent, SidebarService } from '@uc/cms/shared/ui';

@Component({
	selector: 'cms-layout-wrapper',
	templateUrl: './layout-wrapper.component.html',
	imports: [NavbarComponent, RouterModule, SidebarComponent, NgClass],
	providers: [SidebarService],
})
export class LayoutWrapperComponent {}
