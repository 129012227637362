<cms-navbar (toggleSidebar)="sidebar.isOpen.set(!sidebar.isOpen())"></cms-navbar>
<div class="relative flex items-stretch min-h-full">
	<cms-sidebar #sidebar></cms-sidebar>
	<div
		class="w-full px-4 py-6 transition-all duration-150 bg-gray-50 mt-[var(--navbar-height)] min-h-[calc(100vh_-_var(--navbar-height))]"
		[ngClass]="{
			'md:ml-[var(--sidebar-width)] md:!w-[calc(100%_-_var(--sidebar-width))]':
				sidebar.isOpen(),
		}"
	>
		<router-outlet></router-outlet>
	</div>
</div>
