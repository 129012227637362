import { Route } from '@angular/router';
import { LayoutWrapperComponent } from './layout-wrapper.component';
import { authGuard } from './auth.guard';

export const APP_ROUTES: Route[] = [
	{
		path: '',
		component: LayoutWrapperComponent,
		canActivate: [authGuard],
		children: [
			{
				path: 'content',
				loadChildren: () =>
					import('@uc/cms/features/content').then((r) => r.CONTENT_ROUTES),
			},
			{
				path: 'users',
				loadChildren: () =>
					import('@uc/cms/features/users').then((r) => r.USERS_ROUTES),
			},
			{
				path: 'client-success',
				loadChildren: () =>
					import('@uc/cms/features/client-success').then(
						(r) => r.CLIENT_SUCCESS_ROUTES,
					),
			},
		],
	},
	{
		path: 'login',
		loadChildren: () => import('@uc/cms/features/login').then((r) => r.LOGIN_ROUTES),
	},
];
