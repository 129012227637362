import { ApplicationConfig } from '@angular/core';
import {
	InMemoryScrollingFeature,
	InMemoryScrollingOptions,
	provideRouter,
	withComponentInputBinding,
	withInMemoryScrolling,
	withViewTransitions,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { APP_ROUTES } from './app.routes';
import { provideToastr } from 'ngx-toastr';
import { NotificationComponent } from '@uc/shared/ui';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { environment } from '../environments/environment';
import { authInterceptor } from './auth.interceptor';

const scrollConfig: InMemoryScrollingOptions = {
	scrollPositionRestoration: 'top',
	anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
	withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(
			APP_ROUTES,
			inMemoryScrollingFeature,
			withViewTransitions(),
			withComponentInputBinding(),
		),
		provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
		provideAnimations(),
		provideToastr({
			timeOut: 3000,
			progressBar: true,
			progressAnimation: 'increasing',
			closeButton: true,
			toastComponent: NotificationComponent,
		}),
		{
			provide: 'environment',
			useValue: environment,
		},
	],
};
